import React from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class SkillModal extends React.Component {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}>
        <ModalHeader>
          <a href={this.props.url} target="_blank">
            {this.props.skill}
          </a>
        </ModalHeader>
        <ModalBody>{this.props.description}</ModalBody>
        <ModalFooter>
          <Button onClick={this.props.onClose}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default SkillModal;
