import React from "react";

import { Card, CardBody, CardImg, CardTitle, CardSubtitle } from "reactstrap";

class HeroCard extends React.Component {
  render() {
    return (
      <Card className="shadow border-0">
        <CardImg
          src={require(`assets/img/${this.props.asset}/${this.props.image}.jpg`)}
        />
        <CardBody>
          <CardTitle>
            <h6 class={`text-${this.props.color} text-uppercase`}>
              {this.props.title}
            </h6>
          </CardTitle>
          <CardSubtitle>{this.props.subtitle}</CardSubtitle>
        </CardBody>
      </Card>
    );
  }
}

HeroCard.defaultProps = {
  asset: "theme",
  color: "primary",
  icon: "user-run",
  image: "accelerate",
};

export default HeroCard;
