import SkillModal from "components/Modals/SkillModal";
import React from "react";

import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	UncontrolledTooltip,
} from "reactstrap";

class SkillCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		};
	}

	onClick(event) {
		this.setState({ isOpen: true });
	}

	onClose(event) {
		this.setState({ isOpen: false });
	}

	onToggle(event) {
		this.setState({ isOpen: !this.state.isOpen });
	}

	render() {
		return (
			<Col xs="12" sm="6" md="4" lg="2" className="mb-4">
				<Card
					className="shadow border-0"
					onClick={this.onClick.bind(this)}
					style={{ cursor: "pointer" }}
					id={`card-${this.props.skill}`}
				>
					<CardBody className="p-3">
						<Container>
							<Row>
								<Col>
									<img
										class="img-fluid"
										width="100%"
										title={this.props.name}
										alt={this.props.name}
										src={require(
											`assets/img/icons/common/${this.props.skill}.svg`,
										)}
									/>
								</Col>
							</Row>
						</Container>
					</CardBody>
				</Card>
				<SkillModal
					isOpen={this.state.isOpen}
					onClose={this.onClose.bind(this)}
					onToggle={this.onToggle.bind(this)}
					skill={this.props.name}
					description={this.props.description}
					url={this.props.url}
				/>
				<UncontrolledTooltip
					innerClassName="bg-primary"
					delay={0}
					placement="top"
					target={`card-${this.props.skill}`}
				>
					Click to view {this.props.name} skills.
				</UncontrolledTooltip>
			</Col>
		);
	}
}

export default SkillCard;
