import React from "react";
import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	ListGroup,
	ListGroupItem,
} from "reactstrap";

import HeroCard from "components/Cards/HeroCard.js";
import SkillCard from "components/Cards/SkillCard.js";

class Landing extends React.Component {
	render() {
		return (
			<>
				<main ref="main">
					{/* Title Section */}
					<section className="section section-md section-shaped pb-0">
						<Container>
							<Row>
								<Col lg="8">
									<h1 className="display-2">
										Expert DevOps Consulting
										<span className="display-4">
											from decades of diverse experience
										</span>
									</h1>
									{/* <p className="lead text-black">
                  TODO
                </p> */}
								</Col>
							</Row>
						</Container>
					</section>
					{/* Service Cards Section */}
					<section className="section section-sm section-shaped">
						<Container>
							<Row>
								<Col lg="4" className="mb-4">
									<HeroCard
										title="Polish Pipelines"
										subtitle="Accelerate your automation"
										image="accelerate"
									/>
								</Col>
								<Col lg="4" className="mb-4">
									<HeroCard
										title="Modernize Infrastructure"
										subtitle="Simplify your operations"
										image="infrastructure"
									/>
								</Col>
								<Col lg="4">
									<HeroCard
										title="Increase Observability"
										subtitle="Swiftly tackle issues"
										image="observability"
									/>
								</Col>
							</Row>
						</Container>
					</section>
					{/* Individual Skills Section */}
					<section className="section section-sm section-shaped">
						<Container>
							<h1 className="display-3">Primary Services</h1>
							<span className="display-5">
								I offer consulting and contract work with the most popular
								technologies used by DevOps-ified organizations as well as
								assistance with migrating your operations from on-prem
								datacenters into the cloud or hybrid solutions.
							</span>
							<section className="section section-sm">
								<Row>
									<SkillCard
										skill="aws"
										name="Amazon Web Services"
										url="https://aws.amazon.com/"
										description={
											<>
												<p>
													7+ years of experience with AWS APIs and services
													including:
												</p>
												<ListGroup>
													<ListGroupItem>
														Elastic Compute Cloud (EC2)
													</ListGroupItem>
													<ListGroupItem>
														Elastic Container Service
													</ListGroupItem>
													<ListGroupItem>
														Elastic Kubernetes Service
													</ListGroupItem>
													<ListGroupItem>Lambda (Serverless)</ListGroupItem>
													<ListGroupItem>
														Relational Database Service (RDS)
													</ListGroupItem>
													<ListGroupItem>
														Simple Storage Service (S3)
													</ListGroupItem>
													<ListGroupItem>
														Virtual Private Cloud (VPC)
													</ListGroupItem>
												</ListGroup>
											</>
										}
									/>
									<SkillCard
										skill="kubernetes"
										name="Kubernetes"
										url="https://kubernetes.io/"
										description={
											<>
												<p>7+ years of experience in Kubernetes.</p>
												<ListGroup>
													<ListGroupItem>Kubernetes Workloads</ListGroupItem>
													<ListGroupItem>Kubernetes Controllers</ListGroupItem>
													<ListGroupItem>Kubernetes Operators</ListGroupItem>
													<ListGroupItem>
														Kubernetes Custom Resources
													</ListGroupItem>
												</ListGroup>
											</>
										}
									/>
									<SkillCard
										skill="terraform"
										name="Terraform"
										url="https://www.terraform.io/"
										description={
											<>
												<p>7+ years of experience with Terraform.</p>
												<ListGroup>
													<ListGroupItem>Resources</ListGroupItem>
													<ListGroupItem>Data Sources</ListGroupItem>
													<ListGroupItem>State Management</ListGroupItem>
													<ListGroupItem>Lifecycle Management</ListGroupItem>
													<ListGroupItem>Providers</ListGroupItem>
												</ListGroup>
											</>
										}
									/>
									<SkillCard
										skill="gitlab"
										name="GitLab"
										url="https://gitlab.com/"
										description={
											<>
												<p>10+ years of experience with GitLab.</p>
												<ListGroup>
													<ListGroupItem>Projects / Groups</ListGroupItem>
													<ListGroupItem>CI / CD Pipelines</ListGroupItem>
													<ListGroupItem>Artifact Registry</ListGroupItem>
													<ListGroupItem>Container Registry</ListGroupItem>
													<ListGroupItem>Package Registry</ListGroupItem>
													<ListGroupItem>
														HashiCorp Vault Integration
													</ListGroupItem>
												</ListGroup>
											</>
										}
									/>
									<SkillCard
										skill="github"
										name="GitHub"
										url="https://github.com/"
										description={
											<>
												<p>5+ years of experience with GitHub.</p>
												<ListGroup>
													<ListGroupItem>Repositories</ListGroupItem>
													<ListGroupItem>GitHub Actions</ListGroupItem>
													<ListGroupItem>Package Registry</ListGroupItem>
												</ListGroup>
											</>
										}
									/>
									<SkillCard
										skill="grafana"
										name="Grafana"
										url="https://grafana.com/"
										description={
											<>
												<p>7+ years of experience with Grafana.</p>
												<ListGroup>
													<ListGroupItem>Dashboards</ListGroupItem>
													<ListGroupItem>Prometheus</ListGroupItem>
													<ListGroupItem>Querying</ListGroupItem>
													<ListGroupItem>Alerts</ListGroupItem>
												</ListGroup>
											</>
										}
									/>
								</Row>
							</section>
						</Container>
					</section>
					{/* Experience Section */}
					<section className="section section-sm section-shaped">
						<Container>
							<h1 className="display-3">Aerospace Sector Experience</h1>
							<section className="section section-sm">
								<Row>
									<Col lg="4" className="mb-4">
										<HeroCard
											asset="brand"
											image="millennium"
											title="Millennium Space Systems"
											subtitle="Infrastructure Development"
										/>
									</Col>
									<Col lg="4" className="mb-4">
										<HeroCard
											asset="brand"
											image="falcon-exodynamics"
											title="Falcon Exodynamics"
											subtitle="Pipeline Development"
										/>
									</Col>
									<Col lg="4">
										<HeroCard
											asset="brand"
											image="rocketlab"
											title="RocketLab"
											subtitle="Infrastructure Development"
										/>
									</Col>
								</Row>
							</section>
							<h1 className="display-3">Software Sector Experience</h1>
							<section className="section section-sm">
								<Row>
									<Col lg="4" className="mb-4">
										<HeroCard
											asset="brand"
											image="ccpgames"
											title="CCP Games"
											subtitle="Pipeline Development"
										/>
									</Col>
									<Col lg="4" className="mb-4">
										<HeroCard
											asset="brand"
											image="controlant"
											title="Controlant"
											subtitle="Cloud Migration"
										/>
									</Col>
									<Col lg="4">
										<HeroCard
											asset="brand"
											image="zoox"
											title="Zoox"
											subtitle="Pipeline Development"
										/>
									</Col>
								</Row>
							</section>
						</Container>
					</section>
					{/* Contact Section */}
					<section className="section section-sm section-shaped">
						<Container>
							{/* <h1 className="display-3">Contact Me</h1> */}
							<Row>
								<Col>
									<Card className="bg-gradient-secondary shadow">
										<CardBody className="p-lg-5">
											<h4 className="mb-1">Want to work with me?</h4>
											<p className="mt-0">
												<a href="mailto:chris.m.busby@gmail.com">
													Send me an email
												</a>{" "}
												with a description of what projects you'd like some
												consulting or help with and I'll get back to you as soon
												as possible!
											</p>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Container>
					</section>
				</main>
			</>
		);
	}
}

export default Landing;
